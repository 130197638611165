// Packages or third-party libraries
import React, { FC } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { LockIconSVG } from "@epignosis_llc/gnosis/icons";
import { Placement } from "tippy.js";

// Styles
import { passwordIconStyles, passwordTooltipStyles } from "./styles";

// Components
import { CopyToClipboardButton } from "@components";

// Utils
import { t } from "@utils/i18n";
import { SerializedStyles } from "@emotion/react";

type ButtonColor = "primary" | "secondary" | "danger" | "success" | "black";

type EventPasswordTooltipProps = {
  password: string;
  placement?: string;
  buttonColor?: ButtonColor;
};

const getTooltipContent = (password: string): JSX.Element => (
  <div css={passwordTooltipStyles}>
    <Text className="password-text" fontSize="sm">
      {t("signIn.password")}:
    </Text>
    <div className="password-content">
      <Text fontSize="sm" weight="700">
        {password}
      </Text>
      <CopyToClipboardButton textToCopy={password} />
    </div>
  </div>
);

const PasswordTooltip: FC<EventPasswordTooltipProps> = ({
  password,
  placement = "top",
  buttonColor = "black",
}) => (
  <Tooltip
    content={getTooltipContent(password)}
    parentProps={{ "aria-label": t("signIn.password") }}
    placement={placement as Placement}
  >
    <div css={(theme): SerializedStyles => passwordIconStyles(theme, { buttonColor })}>
      <LockIconSVG height={32} />
    </div>
  </Tooltip>
);
export default PasswordTooltip;
