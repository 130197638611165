// Packages or third-party libraries
import React, { FC } from "react";
import { ToolbarPlugin } from "@react-pdf-viewer/toolbar";
import { ToolbarSlot } from "@react-pdf-viewer/default-layout";
import { RenderEnterFullScreenProps } from "@react-pdf-viewer/full-screen";
import { RenderGoToPageProps } from "@react-pdf-viewer/page-navigation";
import { RenderZoomOutProps } from "@react-pdf-viewer/zoom";
import { Button } from "@epignosis_llc/gnosis";
import {
  ZoomInSVG,
  ZoomOutSVG,
  ArrowChevronRightSVG,
  ScreenExpandSVG,
} from "@epignosis_llc/gnosis/icons";

// Styles
import { ToolbarContainer } from "./styles";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

// Hooks
import { useApplyTranslations } from "@hooks";
import { capitalize } from "@utils/helpers";

type PdfViewerToolbarProps = {
  toolbarPluginInstance: ToolbarPlugin;
};

const PdfViewerToolbar: FC<PdfViewerToolbarProps> = ({ toolbarPluginInstance }) => {
  const { Toolbar } = toolbarPluginInstance;
  const { t } = useApplyTranslations();

  return (
    <div css={ToolbarContainer} className="toolbar-container">
      <Toolbar>
        {(props: ToolbarSlot): JSX.Element => {
          const {
            CurrentPageInput,
            EnterFullScreen,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ZoomIn,
            ZoomOut,
          } = props;
          return (
            <>
              <ZoomIn>
                {(props: RenderZoomOutProps): JSX.Element => (
                  <Button
                    className="action-btn"
                    variant="link"
                    noGutters
                    aria-label={t("pdfViewer.zoomIn")}
                    onClick={props.onClick}
                  >
                    <ZoomInSVG width={16} />
                  </Button>
                )}
              </ZoomIn>
              <ZoomOut>
                {(props: RenderZoomOutProps): JSX.Element => (
                  <Button
                    className="action-btn"
                    variant="link"
                    noGutters
                    aria-label={t("pdfViewer.zoomOut")}
                    onClick={props.onClick}
                  >
                    <ZoomOutSVG width={16} />
                  </Button>
                )}
              </ZoomOut>
              <GoToPreviousPage>
                {(props: RenderGoToPageProps): JSX.Element => (
                  <Button
                    className="action-btn"
                    variant="link"
                    noGutters
                    disabled={props.isDisabled}
                    aria-label={capitalize(t("pagination.previousPage"))}
                    onClick={props.onClick}
                  >
                    <ArrowChevronRightSVG height={32} className="previous-page-btn" />
                  </Button>
                )}
              </GoToPreviousPage>
              <div className="input-wrapper">
                <CurrentPageInput />
              </div>
              <div className="number-of-pages">
                / <NumberOfPages />
              </div>
              <GoToNextPage>
                {(props: RenderGoToPageProps): JSX.Element => (
                  <Button
                    className="action-btn"
                    variant="link"
                    noGutters
                    disabled={props.isDisabled}
                    aria-label={capitalize(t("pagination.nextPage"))}
                    onClick={props.onClick}
                  >
                    <ArrowChevronRightSVG height={32} className="next-page-btn" />
                  </Button>
                )}
              </GoToNextPage>
              <EnterFullScreen>
                {(props: RenderEnterFullScreenProps): JSX.Element => (
                  <Button
                    onClick={props.onClick}
                    className="action-btn"
                    variant="link"
                    noGutters
                    aria-label={t("pdfViewer.fullScreen")}
                  >
                    <ScreenExpandSVG width={32} />
                  </Button>
                )}
              </EnterFullScreen>
            </>
          );
        }}
      </Toolbar>
    </div>
  );
};

export default PdfViewerToolbar;
