import React, { FC, FocusEvent, KeyboardEventHandler, useEffect, useState } from "react";
import { CSSObject, SerializedStyles, useTheme } from "@emotion/react";
import CreatableSelect from "react-select/creatable";
import { Label, Tooltip } from "@epignosis_llc/gnosis";
import { InfoCircledSVG } from "@epignosis_llc/gnosis/icons";

import { ControlProps, GroupBase, OptionProps } from "react-select";

import {
  AutocompleteContainer,
  MenuListStyles,
  MultiValueLabelStyles,
  MultiValueRemoveStyles,
  MultiValueStyles,
  SelectContainerStyles,
  SingleValueStyles,
  ValueContainerStyles,
  IndicatorContainer,
} from "./styles";
import {
  ClearIndicatorStyles,
  ControlStyles,
  OptionStyles,
  PlaceholderStyles,
} from "@components/CustomReactSelect/styles";
import { CustomMultiValueRemove } from "@components";
import { SelectOption } from "types/common";

export type CreatableInputProps = {
  className?: string;
  label?: string;
  required?: boolean;
  status?: string;
  placeholder?: string;
  tooltipContent?: string;
  defaultValues?: SelectOption[];
  showLabel?: boolean;
  isDisabled?: boolean;
  ariaLabel: string;
  onChange?: (value: string[]) => void;
};

const createOption = (label: string): SelectOption => ({
  label,
  value: label,
});

const CreatableInput: FC<CreatableInputProps> = ({
  label,
  required = false,
  isDisabled = false,
  status,
  className,
  defaultValues = [],
  placeholder,
  tooltipContent,
  showLabel = true,
  ariaLabel,
  onChange,
}) => {
  const { autocompleteInput } = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState<readonly SelectOption[]>(defaultValues);

  // Custom styles for the components
  const customStyles = {
    clearIndicator: (base: CSSObject): CSSObject =>
      ClearIndicatorStyles({ autocompleteInput, base }),
    control: (
      base: CSSObject,
      state: ControlProps<SelectOption, true, GroupBase<SelectOption>>,
    ): CSSObject => ControlStyles({ autocompleteInput, base, state, status }),
    placeholder: (base: CSSObject): CSSObject => PlaceholderStyles({ autocompleteInput, base }),
    valueContainer: (base: CSSObject): CSSObject => ValueContainerStyles({ base }),
    singleValue: (base: CSSObject): CSSObject => SingleValueStyles({ base }),
    multiValue: (base: CSSObject): CSSObject => MultiValueStyles({ autocompleteInput, base }),
    multiValueLabel: (base: CSSObject): CSSObject => MultiValueLabelStyles({ base }),
    multiValueRemove: (base: CSSObject): CSSObject =>
      MultiValueRemoveStyles({ autocompleteInput, base }),
    container: (base: CSSObject): CSSObject => SelectContainerStyles({ base }),
    menuList: (base: CSSObject): CSSObject => MenuListStyles({ base }),
    option: (
      base: CSSObject,
      state: OptionProps<SelectOption, true, GroupBase<SelectOption>>,
    ): CSSObject => OptionStyles({ autocompleteInput, base, state }),
    indicatorsContainer: (base: CSSObject): CSSObject =>
      IndicatorContainer({
        base,
      }),
  };

  const addItem = (inputValue: string): void => {
    if (!inputValue) return;

    // not add the given input value if there is an item with the same value
    const index = values.findIndex(({ value }) => value === inputValue);
    if (index > -1) return;

    setValues((prev) => [...prev, createOption(inputValue)]);
    setInputValue("");
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    switch (event.code) {
      case "Enter":
      case "Tab": {
        addItem(inputValue);
        event.preventDefault();
      }
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    addItem(inputValue);
    event.preventDefault();
  };

  useEffect(() => {
    onChange && onChange(values.map((item) => item.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div
      css={(): SerializedStyles => AutocompleteContainer(autocompleteInput, { required })}
      className={className}
    >
      {showLabel && (
        <div className="label-container">
          {label && <Label>{label}</Label>}
          {tooltipContent && (
            <Tooltip content={tooltipContent}>
              <InfoCircledSVG height={20} />
            </Tooltip>
          )}
        </div>
      )}

      <CreatableSelect
        aria-label={ariaLabel}
        id="creatable-field"
        isDisabled={isDisabled}
        inputValue={inputValue}
        className="select-container"
        value={values}
        isClearable
        isMulti
        menuIsOpen={false}
        placeholder={placeholder}
        styles={customStyles}
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null,
          MultiValueRemove: CustomMultiValueRemove,
        }}
        onChange={(newValue): void => setValues(newValue)}
        onInputChange={(newValue): void => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default CreatableInput;
